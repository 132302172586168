const sampleData = [
    {
        no_of_bedrooms: "2 BED",
        area_in_sqft: 854,
        suite_no: 101,
        tenant: "Elemo Idris",
        tenants: 1,
        sd: 1575,
        targetRent: 1625,
        baseRent: 1575,
        parking: "",
        extraTenant: "",
        dogFee: "",
        catFee: "",
        totalRent: 1575,
        leaseStart: "2024-09-01",
        leaseEnd: "2025-09-30",
        comments: "",
    },
    {
        no_of_bedrooms: "1 BED",
        area_in_sqft: 650,
        suite_no: 108,
        tenant: "Ahmed Abu",
        tenants: 1,
        sd: 1000,
        targetRent: 1625,
        baseRent: 1625,
        parking: "",
        extraTenant: "",
        dogFee: "",
        catFee: "",
        totalRent: 1625,
        leaseStart: "2024-10-01",
        leaseEnd: "2025-08-30",
        comments: "Not renewing",
    },
    {
        no_of_bedrooms: "2 BED",
        area_in_sqft: 854,
        suite_no: 205,
        tenant: "Mehita Atlia",
        tenants: 1,
        sd: 1000,
        targetRent: 1625,
        baseRent: 1625,
        parking: "",
        extraTenant: "",
        dogFee: "",
        catFee: "",
        totalRent: 1625,
        leaseStart: "2024-01-01",
        leaseEnd: "2025-12-31",
        comments: "Lease sent for Renewal",
    },
];

export {sampleData}