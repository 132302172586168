import axios from "../../../../api/axios";
import { formatDate } from "./helpers";

/**
 * API endpoints for tenant information operations
 */
const ENDPOINTS = {
  LEASE_INFO: "master-sheet/lease-info",
};

/**
 * Maps API response to normalized tenant info object
 * @param {Object} data - The API response data
 * @param {Object} residence - Optional residence information
 * @returns {Object} Normalized tenant info object
 */
const mapResponseToTenantInfo = (data, residence = {}) => {
  const {
    tenants = [],
    occupants = [],
    utility_accounts = "",
    no_of_bedrooms = "",
    area_in_sqft = "",
    suite_no = "",
    security_deposit = "",
    target_rent = "",
    basic_rent = "",
    pets = "",
    lease_start = "",
    lease_end = "",
    comments = "",
    documents = [],
  } = data || {};

  return {
    ...(Object.keys(residence).length && { residence }),
    tenants,
    occupants,
    utility_accounts,
    no_of_bedrooms,
    area_in_sqft,
    suite_no,
    security_deposit,
    target_rent,
    basic_rent,
    pets,
    lease_start: formatDate(lease_start),
    lease_end: formatDate(lease_end),
    comments,
    documents,
  };
};

/**
 * Fetches tenant information by property ID, building name, and suite number
 * @param {string} property_id - Property ID
 * @param {string} building_name - Building name
 * @param {string} primary_id - Primary ID
 * @param {string} suite_no - Suite number
 * @returns {Promise<Object|null>} Tenant information or null if error
 */
export const getTenantInfo = async (property_id, building_name, primary_id, suite_no) => {
  try {
    const { data } = await axios.get(
      `${ENDPOINTS.LEASE_INFO}/${primary_id}/${building_name}/${suite_no}`
    );

    if (data?.status !== 200) return null;

    return mapResponseToTenantInfo(data.data, {
      property_id,
      building_name,
      primary_id,
    });
  } catch (error) {
    console.error("Error fetching tenant info:", error);
    return null;
  }
};

/**
 * Adds new tenant information
 * @param {Object} body - Tenant information to add
 * @returns {Promise<Object|null>} Added tenant information or null if error
 */
export const addTenantInfo = async (body) => {
  try {
    const { data } = await axios.post(ENDPOINTS.LEASE_INFO, body);

    if (data?.status !== 200) return null;

    const tenantInfo = mapResponseToTenantInfo(data.data);
    return { ...tenantInfo, primary_id: data.data.primary_id };
  } catch (error) {
    console.error("Error adding tenant info:", error);
    return null;
  }
};

/**
 * Updates existing tenant information
 * @param {Object} body - Updated tenant information
 * @param {Object} residence - Residence information
 * @returns {Promise<Object|null>} Updated tenant information or null if error
 */
export const updateTenantInfo = async (body, residence) => {
  try {
    const { data } = await axios.put(ENDPOINTS.LEASE_INFO, body);

    if (data?.status !== 200) return null;

    return mapResponseToTenantInfo(data.data, residence);
  } catch (error) {
    console.error("Error updating tenant info:", error);
    return null;
  }
};