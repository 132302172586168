import React, { useState, useEffect } from "react";
import { Card, CardBody, Form, Row, Col, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";
import axios from "../../api/axios";
import { AddTenantForm } from "./TenantForm/AddTenantForm";

const API_ENDPOINT = "master-sheet/property-list";

const PropertySelector = ({
  isFilterOpen,
  setIsFilterOpen,
  action,
  userData,
  filterData,
  setFilterData
}) => {
  const [isAddTenantFormOpen, setIsAddTenantFormOpen] = useState(false)

  useEffect(() => {
    if (userData?.id) fetchPropertyList();
  }, [userData?.id]);

  const fetchPropertyList = async () => {
    try {
      const { data } = await axios.get(`${API_ENDPOINT}?role=${userData.role || ''}&id=${userData.id || ''}`);
      const properties = data.data || [];
      setFilterData((prev) => ({
        ...prev,
        propertyList: properties,
        propertyId: properties.length ? properties[0]._id : "",
        propertyName: properties.length ? properties[0].title : "",
        buildingList: properties.length ? properties[0].building || [] : []
      }));
    } catch (error) {
      console.error("Failed to fetch property list:", error);
    }
  };

  const formatProperty = (item) => ({
    id: item._id,
    value: item.title,
    label: `${item.title}, ${item.location}`,
    building: item.building || [],
  });

  const formatProperties = () => (filterData.propertyList || []).map(formatProperty);
  const formatBuildings = () => filterData?.buildingList?.map((b) => ({ value: b.buildingName, label: b.buildingName })) || [];

  const handlePropertySelectionChange = (property) => {
    setFilterData((prev) => ({
      ...prev,
      propertyId: property?.id || "",
      propertyName: property?.value || "",
      buildingList: property?.building || [],
      buildingName: ""
    }));
  };

  const handleBuildingSelectionChange = (building) => {
    setFilterData((prev) => ({
      ...prev,
      buildingName: building?.value || ""
    }));
  };

  return (
    <Card>
      <CardBody>
        <Form>
          <Row>
            <Col md={5}>
              <Label>Property Name</Label>
              <Select
                value={filterData.propertyId ? { value: filterData.propertyName, label: filterData.propertyName } : null}
                options={formatProperties()}
                placeholder="Search By Name"
                onChange={handlePropertySelectionChange}
                className="property-select"
              />
            </Col>
            {filterData.buildingList?.length > 0 && (
              <Col md={4}>
                <FormGroup>
                  <Label>Building Name</Label>
                  <Select
                    value={filterData.buildingName ? { value: filterData.buildingName, label: filterData.buildingName } : null}
                    options={formatBuildings()}
                    placeholder="Search By Building Name"
                    onChange={handleBuildingSelectionChange}
                    className="building-select"
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={3} className="d-flex align-items-end">
              <Button color="secondary" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                {isFilterOpen ? "Collapse" : "Filters"}
              </Button>
              <Button
                color="primary"
                className="ms-2"
                onClick={action}
                disabled={
                  !filterData.propertyId ||
                  (filterData.buildingList.length > 0 && !filterData.buildingName)
                }
              >
                Import/Export
              </Button>
              <Button
                color="primary"
                className="ms-2"
                onClick={() => setIsAddTenantFormOpen(true)}
                disabled={
                  !filterData.propertyId ||
                  (filterData.buildingList.length > 0 && !filterData.buildingName)
                }
              >
                +
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <AddTenantForm show={isAddTenantFormOpen} handleClose={() => setIsAddTenantFormOpen(false)} />
    </Card>
  );
};

export { PropertySelector };
