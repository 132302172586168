import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Card, Col, Row } from 'reactstrap';
import { formatBytes } from './formatbytes';
import { getIn } from 'formik';

const FileUploadField = ({ name, label, formik }) => {
    const [isDragActive, setIsDragActive] = useState(false);
    const [previews, setPreviews] = useState([]);

    useEffect(() => {
        return () => {
            previews.forEach(URL.revokeObjectURL);
        };
    }, [previews]);

    if (!formik) return null;

    const { setFieldValue, setFieldTouched, values, touched, errors } = formik;
    const field = values[name] || [];

    const handleAcceptedFiles = (acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) => {
            const preview = URL.createObjectURL(file);
            return {
                file,
                preview,
                formattedSize: formatBytes(file.size)
            };
        });

        // Combine new files with existing files in Formik
        const updatedFiles = [...field, ...newFiles];
        setFieldValue(name, updatedFiles);
        setFieldTouched(name, true);

        // Update state with complete file objects
        setPreviews(prevPreviews => {
            // Revoke old previews to prevent memory leaks
            prevPreviews.forEach(({ preview }) => URL.revokeObjectURL(preview));

            return updatedFiles;
        });

        setIsDragActive(false);
    };

    const handleRemoveFile = (fileToRemove) => {
        // Remove file from Formik
        const updatedFiles = field.filter(({ preview }) => preview !== fileToRemove.preview);
        setFieldValue(name, updatedFiles);

        // Remove preview and revoke URL
        URL.revokeObjectURL(fileToRemove.preview);
        setPreviews(prevPreviews =>
            prevPreviews.filter(({ preview }) => preview !== fileToRemove.preview)
        );
    };


    const meta = {
        touched: getIn(touched, name),
        error: getIn(errors, name),
    };

    return (
        <div className="w-full">
            <label className="block text-sm font-medium text-gray-700 mb-2 text-center w-100">
                {label}
            </label>
            <Dropzone
                onDrop={handleAcceptedFiles}
                onDragEnter={() => setIsDragActive(true)}
                onDragLeave={() => setIsDragActive(false)}
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps()}
                        className={`
                            border-2 border-dashed rounded-lg p-6 text-center 
                            transition-all duration-300 ease-in-out
                            ${isDragActive
                                ? 'border-blue-500 bg-blue-50 scale-105'
                                : 'border-gray-300 hover:border-blue-400'}
                            ${meta.touched && meta.error ? 'border-red-500' : ''}
                        `}
                    >
                        <input {...getInputProps()} accept=".jpg,.jpeg,.png" />
                        <div className={`${previews.length > 0 ? 'd-none' : ''}`}>
                            <svg
                                className={`
                                    mx-auto h-16 w-16 
                                    transition-colors duration-300
                                    ${isDragActive ? 'text-blue-500' : 'text-gray-400'}
                                `}
                                width={120}
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>

                        <p className={`
                            text-sm transition-colors duration-300
                            ${isDragActive ? 'text-blue-600' : 'text-gray-600'}
                        `}>
                            {isDragActive
                                ? 'Drop files here'
                                : 'Drag and drop files or click to upload'}
                        </p>
                    </div>
                )}
            </Dropzone>

            <div
                className="dropzone-previews mt-3"
                id="file-previews"
            >
                {previews &&
                    previews.map((f, i) => {
                        return (
                            <Card
                                className="mt-1 mb-0 shadow-sm border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                            />
                                        </Col>
                                        <Col>
                                            <div
                                                to="#"
                                                className="text-muted font-weight-bold"
                                            >
                                                {f.name}
                                            </div>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                        <Col className="col-auto">
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={() => handleRemoveFile(f)}
                                            >
                                                <i className="fa-solid fa-trash"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}
            </div>

            {meta.touched && meta.error && (
                <div className="text-red-500 text-sm bg-red-50 p-2 row text-center w-100 flex items-center space-x-2">
                    <div class="text-danger">{meta.error} <svg
                        className="h-5 w-5 text-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        width={20}
                    >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                        />
                    </svg></div>
                </div>
            )}
        </div>
    );
};

export { FileUploadField };