import React from "react";
import { Collapse, Card, CardBody, Form, Row, Col, Label, Input } from "reactstrap";

const FilterSection = ({ isFilterOpen, filters, filterData, handleFilterChange }) => {
    return (
        <>
            <Collapse isOpen={isFilterOpen}>
                <Card>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col md={2}>
                                    <Label>Tenant Name</Label>
                                    <Input
                                        type="text"
                                        name="tenant"
                                        value={filterData.tenant || ""}
                                        onChange={handleFilterChange}
                                        placeholder="Search Tenant"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Label>Property Type</Label>
                                    <Input
                                        type="select"
                                        name="propertyType"
                                        value={filterData.propertyType || ""}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Select Type</option>
                                        {filters.propertyTypes.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col md={2}>
                                    <Label>Suite Number</Label>
                                    <Input
                                        type="select"
                                        name="suite"
                                        value={filterData.suite || ""}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Select Suite</option>
                                        {filters.suites.map((suite) => (
                                            <option key={suite} value={suite}>{suite}</option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col md={2}>
                                    <Label>Lease Start</Label>
                                    <Input
                                        type="date"
                                        name="leaseStart"
                                        value={filterData.leaseStart || ""}
                                        onChange={handleFilterChange}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Label>Lease End</Label>
                                    <Input
                                        type="date"
                                        name="leaseEnd"
                                        value={filterData.leaseEnd || ""}
                                        onChange={handleFilterChange}
                                    />
                                </Col>
                                <Col md={1}>
                                    <Label>Min Rent</Label>
                                    <Input
                                        type="number"
                                        name="minRent"
                                        value={filterData.minRent || ""}
                                        onChange={handleFilterChange}
                                        placeholder="Min"
                                    />
                                </Col>
                                <Col md={1}>
                                    <Label>Max Rent</Label>
                                    <Input
                                        type="number"
                                        name="maxRent"
                                        value={filterData.maxRent || ""}
                                        onChange={handleFilterChange}
                                        placeholder="Max"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Collapse>
        </>
    );
};

export { FilterSection };
