import React, { useState } from "react";
import { Table, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import './mastersheet.css';

const MasterSheetTable = ({ config }) => {
    const [hoveredRow, setHoveredRow] = useState(null);

    const renderTooltip = (row) => (
        <Tooltip id={`tooltip-${row.suite}`} style={{ zIndex: 9999 }} >
            {row?.tenant ? `Tenant: ${row.tenant || "N/A"} having Base Rent: $${row.baseRent || "N/A"}. Click to edit tenant details.`
                : `Tenant: ${row.tenant || "N/A"} having Base Rent: $${row.baseRent || "N/A"}. Click to assign this slot a suite.`}
        </Tooltip>
    );

    return (
        <Container className="mt-4">
            <Table bordered hover responsive className="mastersheet-table">
                <thead>
                    <tr>
                        <th>BED</th>
                        <th>SQ FT</th>
                        <th>SUITE</th>
                        <th>TENANT</th>
                        <th># TENANTS</th>
                        <th>SD</th>
                        <th>TARGET RENT</th>
                        <th>BASE RENT</th>
                        <th>PARKING</th>
                        <th>EXTRA TENANT</th>
                        <th>DOG FEE</th>
                        <th>CAT FEE</th>
                        <th>TOTAL RENT</th>
                        <th>LEASE START</th>
                        <th>LEASE END</th>
                        <th>COMMENTS</th>
                    </tr>
                </thead>
                <tbody>
                    {config.length > 0 ? (
                        config.map((row, index) => (
                            <OverlayTrigger
                                key={index}
                                placement="left"
                                overlay={renderTooltip(row)}
                            >
                                <tr
                                    className={`table-row ${hoveredRow === index ? "hovered-row" : ""}`}
                                    onMouseEnter={() => setHoveredRow(index)}
                                    onMouseLeave={() => setHoveredRow(null)}
                                >
                                    {[
                                        { key: "bed", value: row.bed },
                                        { key: "sqft", value: row.sqft },
                                        { key: "suite", value: row.suite, className: row.suite ? "table-success" : "table-danger" },
                                        { key: "tenant", value: row.tenant || "N/A" },
                                        { key: "tenants", value: row.tenants || "N/A" },
                                        { key: "sd", value: row.sd || "N/A" },
                                        { key: "targetRent", value: `$${row.targetRent || "N/A"}` },
                                        { key: "baseRent", value: `$${row.baseRent || "N/A"}` },
                                        { key: "parking", value: row.parking || "N/A" },
                                        { key: "extraTenant", value: row.extraTenant || "N/A" },
                                        { key: "dogFee", value: row.dogFee || "N/A" },
                                        { key: "catFee", value: row.catFee || "N/A" },
                                        { key: "totalRent", value: `$${row.totalRent || "N/A"}` },
                                        { key: "leaseStart", value: row.leaseStart || "N/A" },
                                        { key: "leaseEnd", value: row.leaseEnd || "N/A" },
                                        { key: "comments", value: row.comments || "N/A" }
                                    ].map(({ key, value, className }) => (
                                        <td key={key} className={`${className || ""} ${hoveredRow === index ? "hovered-row" : ""}`}>
                                            {value}
                                        </td>
                                    ))}
                                </tr>

                            </OverlayTrigger>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="16" className="text-center">
                                No data available.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
};

export { MasterSheetTable };
