import React, { useState } from "react";
import { Card, CardHeader, CardBody, Col, Row, Button } from "reactstrap";
import { saveAs } from "file-saver";
import axios from "axios";
import Swal from "sweetalert2";
import { FilterSection } from "./FilterSection";
import { MasterSheetTable } from "./MasterSheetTable";
import { sampleData as activeMasterSheet } from "./constants";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { PropertySelector } from "./PropertySelector"; // Updated import path

const MasterSheet = () => {
    const { userData } = JSON.parse(window.localStorage.getItem("authUser") || '{"userData": {}}');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterData, setFilterData] = useState({
        tenant: "",
        tenantEmail: "",
        suite: "",
        leaseStart: "",
        leaseEnd: "",
        minRent: "",
        maxRent: "",
        propertyId: "",
        propertyName: "",
        buildingName: ""
    });

    const filters = {
        propertyTypes: ["Apartment", "Commercial", "House"],
        suites: ["101", "102", "103", "201"]
    };

    const handleMasterSheetAction = async () => {
        try {
            const result = await Swal.fire({
                title: 'Choose an Action',
                text: `Do you want to import a file to the ${filterData?.propertyName} Master Sheet or download the Master Sheet?`,
                icon: 'question',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Download Master Sheet',
                denyButtonText: 'Import File',
                cancelButtonText: 'Cancel',
            });
    
            if (result.isConfirmed) {
                // User chose to download the Master Sheet
                const downloadConfirm = await Swal.fire({
                    title: 'Are you sure?',
                    text: 'Do you want to download the Master Sheet Excel file?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, download!',
                    cancelButtonText: 'No, cancel',
                });
    
                if (downloadConfirm.isConfirmed) {
                    const response = await axios.post(
                        '/master-sheet/generate-excel',
                        filterData,
                        { responseType: 'blob' }
                    );
    
                    const file = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    saveAs(file, `MasterSheet.xlsx`);
                    Swal.fire('Success!', 'The Excel file has been downloaded.', 'success');
                } else {
                    Swal.fire('Cancelled', 'The download was cancelled.', 'info');
                }
            } else if (result.isDenied) {
                // User chose to import a file
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = '.xlsx, .xls';
                fileInput.style.display = 'none';
    
                fileInput.addEventListener('change', async (event) => {
                    const file = event.target.files[0];
                    if (file) {
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('propertyId', filterData?.propertyId || '');
                        formData.append('buildingName', filterData?.buildingName || '');
    
                        try {
                            await axios.post('/master-sheet/import-excel', formData, {
                                headers: { 'Content-Type': 'multipart/form-data' }
                            });
                            Swal.fire('Success!', 'The file has been imported successfully.', 'success');
                        } catch (error) {
                            console.error('Error importing file:', error);
                            Swal.fire('Error', 'An error occurred while importing the file.', 'error');
                        }
                    }
                });
    
                document.body.appendChild(fileInput);
                fileInput.click();
                document.body.removeChild(fileInput);
            }
        } catch (error) {
            console.error('Error handling Master Sheet action:', error);
            Swal.fire('Error', 'An unexpected error occurred.', 'error');
        }
    };
    

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prev) => ({ ...prev, [name]: value }));
    };
    

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Home" breadcrumbItem="Master Sheet" />
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <PropertySelector
                                    isFilterOpen={isFilterOpen}
                                    setIsFilterOpen={setIsFilterOpen}
                                    action={handleMasterSheetAction}
                                    userData={userData}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                />
                                <FilterSection
                                    isFilterOpen={isFilterOpen}
                                    filters={filters}
                                    filterData={filterData}
                                    handleFilterChange={handleFilterChange}
                                />
                            </CardHeader>
                            <CardBody>
                                <MasterSheetTable config={activeMasterSheet} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export { MasterSheet };